import axios from "axios";
import { AppConfig } from "./../config";

export const API = axios.create({
  baseURL: AppConfig.BASE_URL,
});

API.interceptors.request.use(function (config) {
  const jwt_token: any = sessionStorage.getItem("jwt_token");
  config.headers["Content-Type"] = "application/json";
  config.headers.Authorization = jwt_token ? jwt_token : "";
  return config;
});

API.interceptors.response.use(
  (response) => {
    if (response.data) {
      return response.data;
    } else {
      return response.data ? response.data : {};
    }
  },
  (error) => {
    throw new Error(error);
  }
);
