export const FormatIndianDate = (isoDate: Date, type: any) => {
  const targetTime: any = new Date(isoDate);
  const indiaTime = new Date(
    targetTime.toLocaleString("en-US", { timeZone: "Asia/Kolkata" })
  );

  const now: any = new Date();
  const diffInSeconds = Math.floor((now - targetTime) / 1000);

  let relativeTime = "";

  if (diffInSeconds < 60) {
    relativeTime = `${diffInSeconds} ${
      diffInSeconds === 1 ? "second ago" : "seconds ago"
    }`;
  } else if (diffInSeconds < 3600) {
    const minutes = Math.floor(diffInSeconds / 60);
    relativeTime = `${minutes} ${minutes === 1 ? "minute ago" : "minutes ago"}`;
  } else if (diffInSeconds < 86400) {
    const hours = Math.floor(diffInSeconds / 3600);
    relativeTime = `${hours} ${hours === 1 ? "hour ago" : "hours ago"}`;
  } else if (diffInSeconds < 604800) {
    const days = Math.floor(diffInSeconds / 86400);
    relativeTime = `${days} ${days === 1 ? "day ago" : "days ago"}`;
  } else if (diffInSeconds < 2592000) {
    const weeks = Math.floor(diffInSeconds / 604800);
    relativeTime = `${weeks} ${weeks === 1 ? "week ago" : "weeks ago"}`;
  } else if (diffInSeconds < 31536000) {
    const months = Math.floor(diffInSeconds / 2592000);
    relativeTime = `${months} ${months === 1 ? "month ago" : "months ago"}`;
  } else {
    const years = Math.floor(diffInSeconds / 31536000);
    relativeTime = `${years} ${years === 1 ? "year ago" : "years ago"}`;
  }

  const formattedDate = indiaTime.toLocaleString("en-IN", {
    year: "numeric",
    month: "short",
    day: "2-digit",
    hour: "2-digit",
    minute: "2-digit",
    second: undefined,
    hour12: true,
  });
  if (type === "datetime") {
    return `${formattedDate} (${relativeTime})`;
  } else {
    return formattedDate ;
  }
};
