import moment from "moment";

export const DateFormate = (date: any, formate: string) => {
  if(date){
  return moment(date).format(formate);
  }
  else{
    return ;
  }
};
