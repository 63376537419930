import React, { useEffect, useState } from "react";
import { ApisService } from "../../../../../app/core/apis.service";
import {
  dataDecrypt,
  encrypt,
} from "../../../../../app/utility/encryption-helper";
import { DataTable } from "../../../../../components/data-table";
import { TransactionsDataGridCols } from "./data-grid-cols";
import { DateRangeSelector } from "../../../../../components/date-filter/daterangeselector";
import moment from "moment";
import { useParams } from "react-router-dom";
import { DateFormate } from "../../../../../app/utility/dateformate";

export const OrdersList = () => {
  const params = useParams();
  const initialPayload = {
    buId: params.org,
    filters: {
      DateFrom: null,
      DateTo: null,
      status: null,
      name: null,
      transaction_type: null,
      offline: null,
    },
  };
  const [loading, setLoading] = useState<boolean>(false);
  const [pageCount, setPageCount] = useState<any>({
    limit: 10,
    page: 0,
  });
  const [search, setSearchText] = useState("");
  const [lastVisitFrom, setLastVisitFrom] = useState<any>();
  const [lastVisitTo, setLastVisitTo] = useState<any>();
  const [clearDates, setClearDates] = useState(false);
  const [selectDate, setSelectData] = useState<any>();
  const [transactionsData, setTransactionsData] = useState<any>([]);

  useEffect(() => {
    loadTransactions(initialPayload);
  }, []);
  async function loadTransactions(payload: any): Promise<void> {
    setClearDates(false);
    setLoading(true);
    try {
      const encryptedData = encrypt(JSON.stringify(payload));
      const res = await ApisService.transactionApi(
        "findByTransactionBuId",
        encryptedData
      );
      const transactionsData = JSON.parse(dataDecrypt(res.data.data));
      const response = transactionsData?.results;

      if (response?.length > 0) {
        response.forEach((element: any) => {
          element.date = moment(element?.created_date).format("DD-MM-YYYY");
          element.name = element?.buyer?.name;
          element.buyer_app = element?.ondc?.buyer_app;
        });
        const orderData = response.filter(
          (order: any) => order.type === "ONDC"
        );
        setTransactionsData(orderData);
      } else {
        setTransactionsData([]);
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.error("Error in transaction:", error);
    }
  }

  useEffect(() => {
    if (selectDate) {
      setLastVisitFrom(DateFormate(selectDate[0], "YYYY-MM-DD"));
      setLastVisitTo(DateFormate(selectDate[1], "YYYY-MM-DD"));
    }
  }, [selectDate]);

  const onPageChange = (data: any) => {
    setPageCount((prev: any) => ({ ...prev, limit: data }));
  };

  const onSelectPage = (data: any) => {
    setPageCount((prev: any) => ({ ...prev, page: data }));
  };

  const getDateRange = (data: any) => {
    setSelectData(data);
  };

  const handleInput = (e: any) => {
    setSearchText(e.target.value);
  };

  const onSearchData = () => {
    const filters: any = {
      search,
      lastVisitFrom,
      lastVisitTo,
    };
    console.log("Searching with filters:", filters);

    if (filters.search || filters.lastVisitFrom || filters.lastVisitTo) {
      let payload: any = {
        buId: params.org,
        filters: {
          DateFrom: filters.lastVisitFrom ? filters.lastVisitFrom : null,
          DateTo: filters.lastVisitTo ? filters.lastVisitTo : null,
          status: null,
          name: filters.search ? filters.search : null,
          transaction_type: null,
          offline: null,
        },
      };
      loadTransactions(payload);
    } else {
      console.log("Please fill at least one search field.");
    }
  };

  const onClear = () => {
    setSearchText("");
    loadTransactions(initialPayload);
    // setPageCount(initialPageCount);
    // getPatientsList(initialPageCount);
    setClearDates(true);
  };

  return (
    <div>
      <div className="row mt-1">
        <div className="col-md-4 text-start my-2">
          <div className="text-dark fs-6 fst-normal">Search</div>
          <input
            name="search"
            className="form-control py-2"
            placeholder="Search . . ."
            value={search}
            onChange={(e) => handleInput(e)}
          />
        </div>
        <div className="col-md-4 my-2">
          <div className="text-dark fs-6 fst-normal">Date</div>
          <DateRangeSelector
            handleDataRange={getDateRange}
            datefilterApply={false}
            clearDates={clearDates}
          />
        </div>
        <div className="col-md-4 my-2 text-end mt-4">
          <button
            className="btn btn-primary primary-bg px-4 py-2 mt-3"
            onClick={() => onSearchData()}
          >
            <span>Search</span>
          </button>
          <button
            className="btn btn-secondary px-4 py-2 mt-3 mx-2"
            onClick={() => onClear()}
          >
            <span>Clear</span>
          </button>
        </div>
      </div>
      <div className="col-12 px-0 my-2">
        {loading ? (
          <div className="text-center p-5">
            <div className="spinner-border" role="status">
              <span className="sr-only"></span>
            </div>
          </div>
        ) : (
          <DataTable
            tableData={transactionsData}
            TableCols={TransactionsDataGridCols}
            onActions={(e: any) => {}}
            onPageChange={(e: any) => onPageChange(e)}
            onSelectPageChange={(e: any) => onSelectPage(e)}
            pageCount={pageCount}
          />
        )}
      </div>
    </div>
  );
};
