import React, { useEffect, useState } from "react";
import { DataTable } from "../../custom-table";
import { Loader } from "../../loader";
import { OrganizationProductsDataGridCols } from "./data-grid-cols";
import { Search } from "../../search/search";
import { dataDecrypt, encrypt } from "../../../app/utility/encryption-helper";
import { ApisService } from "../../../app/core/apis.service";
import { useNavigate, useParams } from "react-router-dom";
import { IMAGE_PATH } from "../../../config";

export const OrgProducts = () => {
  const params = useParams();
  const navigate = useNavigate();
  const [searchText, setSearchText] = useState("");
  const [loading, setLoading] = useState<boolean>(false);
  const [pageCount, setPageCount] = useState<any>({
    id: params.org,
    filter: {
      limit: 10,
      page: 0,
    },
  });
  const [productsData, setProductsData] = useState<any>([]);
  const [filterProductData, setFilterProductData] = useState<any>([]);

  useEffect(() => {
    loadProducts();
  }, []);

  async function loadProducts(): Promise<void> {
    setLoading(true);
    try {
      let encryptedData = encrypt(JSON.stringify(pageCount));
      console.log(encryptedData, "encrypted");
      const res = await ApisService.productApi(
        "findProductList_v2",
        encryptedData
      );
      const productsData = JSON.parse(dataDecrypt(res.data.data));
      setProductsData(productsData.results);
      setFilterProductData(productsData.results)
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.error("Error in transaction:", error);
    }
  }

  const getProductInfo = (item: any) => {
    console.log(item, "item");
    navigate(
      `/dashboard/organizations/org-details/${params.org}/products/${item.id}`
    );
  };

  const handleInput = (value: string) => {
    setSearchText(value);
    if (value.trim() === "") {
      setProductsData(filterProductData);
    } else {
      const filtered = filterProductData.filter(
        (item: any) =>
          item?.name?.toLowerCase().includes(value?.toLowerCase()) 
      );
      setProductsData(filtered);
    }
  };

  return (
    <div className="">
      <div className="row mt-2">
        <div className="col-md-12">

        <input type="search" className="form-control rounded-pill primary-border fs-6 mt-2 py-2 box-shadow" placeholder="Search By Product Name" value={searchText} onChange={(e) => handleInput(e.target.value)}/>
        </div>
      </div>
      <div className="col-12 px-0 my-2">
        {loading && (
          <div className="text-center p-5">
            <div className="spinner-border" role="status">
              <span className="sr-only"></span>
            </div>
          </div>
        )}
        {!loading && (
          <div>
            <div className="row">
              {productsData &&
                productsData?.map((value: any, index: any) => (
                  <div className="col-md-2" key={index}>
                    <div
                      className="card mb-2 cursor-pointer"
                      onClick={() => getProductInfo(value)}
                    >
                      <img
                        src={`${IMAGE_PATH}/${value?.images || "default.png"}`}
                        alt="product image"
                        className="rounded-top object-fit-cover"
                        style={{ height: "100px" }}
                      />
                      <h6 className="text-center my-3 text-truncate px-2">
                        {value?.name}
                      </h6>
                      <div className="px-2 lh-1">
                        Price : ₹ {value?.batch_pricing[0]?.price}
                      </div>
                      <div className="px-2">
                        Quantity : {value?.batch_pricing[0]?.quantity}{" "}
                        <span className="ms-1">
                          {value?.batch_pricing[0]?.quantity_in}
                        </span>
                      </div>
                    </div>
                  </div>
                ))}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};
