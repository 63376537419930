import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { OrgProducts } from "../../../components/organization-tabs/org-products/org-products";
import { OrgUsers } from "../../../components/organization-tabs/org-users/org-users";
import { OrgDetails } from "./org-details";
import { Breadcrumb } from "../../../components/breadcum";
import { OndcOrdersInfo } from "./ondc-orders/info";
import { OnlineConnections } from "./customers-list/online-connections/list";
import { OfflineConnections } from "./customers-list/offline-connections/list";

export const OrgTabInfo = () => {
  const navigate = useNavigate();
  const params = useParams();

  const tabs = [
    { id: "1", title: "Menu System", route: "menu-system", content: <OrgDetails /> },
    { id: "2", title: "Users", route: "users", content: <OrgUsers /> },
    { id: "3", title: "Products", route: "products", content: <OrgProducts /> },
    { id: "4", title: "Online Connections", route: "online-connections", content: <OnlineConnections /> },
    { id: "5", title: "Offline Connections", route: "offline-connections", content: <OfflineConnections /> },
    { id: "6", title: "ONDC Orders", route: "ondc-orders", content: <OndcOrdersInfo /> },
  ];

  const [activeTab, setActiveTab] = useState(tabs[0].id);

  useEffect(() => {
    // Sync activeTab with URL
    const currentTab = tabs.find((tab) => tab.route === params?.tabRoute);
    if (currentTab) {
      setActiveTab(currentTab.id);
    }
  }, [params?.tabRoute]);

  useEffect(() => {
    if (!params?.tabRoute) {
      navigate(`/dashboard/organizations/org-details/${params?.org}/${tabs[0].route}`);
    }
  }, [params, navigate]);

  const handleTabClick = (tabId: string) => {
    const selectedTab = tabs.find((tab) => tab.id === tabId);
    if (selectedTab) {
      setActiveTab(tabId);
      navigate(`/dashboard/organizations/org-details/${params?.org}/${selectedTab.route}`);
    }
  };

  const [breadcrumbInfo, setBreadcrumbInfo] = useState<any>([
    {
      label: "Business Units",
      value: "Business Units",
      routerLink: `/dashboard/organizations`,
      isActive: false,
    },
    {
      label: "Organization",
      value: "Info",
      routerLink: `/dashboard/organizations/org-details/${params?.org}/${params.tabRoute}`,
      isActive: true,
    },
  ]);

  return (
    <div className="container-fluid content-bg-color">
      <div>
        <Breadcrumb breadCrumbList={breadcrumbInfo}/>
      </div>
      <ul className="nav nav-tabs" id="myTab" role="tablist">
        {tabs.map((tab) => (
          <li className="nav-item" role="presentation" key={tab.id}>
            <button
              className={`nav-link ${activeTab === tab.id ? "active" : ""}`}
              id={`${tab.id}-tab`}
              onClick={() => handleTabClick(tab.id)}
              data-bs-toggle="tab"
              type="button"
              role="tab"
              aria-controls={tab.id}
              aria-selected={activeTab === tab.id}
            >
              {tab.title}
            </button>
          </li>
        ))}
      </ul>
      <div className="tab-content" id="myTabContent">
        {tabs.map((tab) => (
          <div
            className={`tab-pane fade ${activeTab === tab.id ? "show active" : ""}`}
            id={tab.id}
            role="tabpanel"
            aria-labelledby={`${tab.id}-tab`}
            key={tab.id}
          >
            {activeTab === tab.id && tab.content}
          </div>
        ))}
      </div>
    </div>
  );
};
