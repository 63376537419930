import { useEffect, useState } from "react";
import { OndcService } from "../../../app/core/ondc.service";
import { DataTable } from "../../../components/data-table";
import { IgmDataGridCols } from "./data-grid-cols";
import { useNavigate } from "react-router-dom";

export const OndcIgmData = () => {
  const [searchText, setSearchText] = useState("");
  const [loading, setLoading] = useState(false);
  const [igmData, setIgmData] = useState<any>();
  const [originalImgData, setOriginalImgData] = useState<any>();
  const navigate = useNavigate();

  useEffect(() => {
    getOndcIgmData();
  }, []);

  const getOndcIgmData = async () => {
    setLoading(true);
    try {
      const res = await OndcService.getOndcIgmData();
      setLoading(false);
      console.log(res);
      res.forEach((el: any) => {
        el.bap_id = el?.context?.bap_id;
        el.category = el?.message?.issue?.category;
        el.sub_category = el?.message?.issue?.sub_category;
        el.issue_type = el?.message?.issue?.issue_type;
        el.order_id = el?.message?.issue?.order_details?.id;
        el.status = el?.message?.issue?.status;
        el.view = (
          <button
            className="px-5 py-1 rounded primary-bg text-white border-0"
            onClick={() => getTicketData(el)}
          >
            View
          </button>
        );
      });
      setIgmData(res);
      setOriginalImgData(res);
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };

  const getTicketData = (data: any) => {
    console.log(data, "ticket data");
    navigate(`/dashboard/ondc-igm/${data?._id}`);
  };

  const handleInput = (value: string) => {
    setSearchText(value);
    if (value.trim() === "") {
      setIgmData(originalImgData);
    } else {
      const filtered = originalImgData.filter(
        (item: any) =>
          item?.bap_id?.toLowerCase().includes(value?.toLowerCase()) ||
          item?.category?.toLowerCase().includes(value?.toLowerCase()) ||
          item?.sub_category?.toLowerCase().includes(value?.toLowerCase()) ||
          item?.issue_type?.toLowerCase().includes(value?.toLowerCase()) ||
          item?.order_id?.toLowerCase().includes(value?.toLowerCase()) ||
          item?.status?.toLowerCase().includes(value?.toLowerCase())
      );
      setIgmData(filtered);
    }
  };
  return (
    <div className="container-fluid content-bg-color">
      <h4 className="primary-color fw-bold mt-3">ONDC IGM</h4>
      <div className="row">
          <div className="col-md-4 my-3">
            <input
              type="search"
              className="form-control p-2"
              placeholder="Search"
              value={searchText}
              onChange={(e) => handleInput(e.target.value)}
            />
          </div>
        <div className="col-md-3 my-3"></div>
      </div>
      <div className="row ">
        <div className="col-12 px-0">
          {loading && (
            <div className="text-center p-5">
              <div className="spinner-border" role="status">
                <span className="sr-only"></span>
              </div>
            </div>
          )}
          {!loading && (
            <>
              <DataTable tableData={igmData} TableCols={IgmDataGridCols} />
            </>
          )}
        </div>
      </div>
    </div>
  );
};
