import React, { useEffect, useState } from "react";
import {
  dataDecrypt,
  encrypt,
} from "../../../../../app/utility/encryption-helper";
import { ApisService } from "../../../../../app/core/apis.service";
import { Search } from "../../../../../components/search/search";
import { DataTable } from "../../../../../components/data-table";
import { TransactionsDataGridCols } from "./data-grid-cols";
import { Form } from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";
import { TiHomeOutline } from "react-icons/ti";
import { CiLocationOn } from "react-icons/ci";
import { PiPhoneCallLight } from "react-icons/pi";

export const OnlineConnections = () => {
  const params = useParams();
  const navigate = useNavigate();
  const [searchText, setSearchText] = useState("");
  const [loading, setLoading] = useState<boolean>(false);
  const [pageCount, setPageCount] = useState<any>({
    limit: 10,
    page: 0,
  });
  const [transactionsData, setTransactionsData] = useState<any>([]);
  const [originalConnectionData, setOriginalConnectionData] = useState<any>([]);
  const [clearDates, setClearDates] = useState(false);
  const [selectDate, setSelectData] = useState<any>();
  const [connectionType, setConnectionType] = useState<string>("External");

  useEffect(() => {
    loadOnlineConnections(connectionType);
    setSearchText("")
  }, [connectionType]);

  async function loadOnlineConnections(connectionType: any): Promise<void> {
    setLoading(true);
    if (connectionType === "External") {
      externalConnections();
    } else {
      internalConnections();
    }
  }

  async function externalConnections(): Promise<void> {
    setLoading(true);
    try {
      let payload = {
        buId: params.org,
        filters: { bu_name: null, contactNumber: null },
      };
      let encryptedData = encrypt(JSON.stringify(payload));
      const res = await ApisService.createInvitation(
        "findAllConnections",
        encryptedData
      );
      const transactionsData = JSON.parse(dataDecrypt(res.data.data));
      setTransactionsData(transactionsData.results);
      setOriginalConnectionData(transactionsData.results);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.error("Error in transaction:", error);
    }
  }

  async function internalConnections(): Promise<void> {
    setLoading(true);
    try {
      let payload = {
        id: sessionStorage.getItem("userId"),
        filters: { bu_name: null, contactNumber: null },
      };
      let encryptedData = encrypt(JSON.stringify(payload));
      const res = await ApisService.getOrganizationData(
        "findOrgByUser_v2",
        encryptedData
      );
      const transactionsData = JSON.parse(dataDecrypt(res.data.data));
      setOriginalConnectionData(transactionsData);
      setTransactionsData(transactionsData);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.error("Error in transaction:", error);
    }
  }

  const handleInput = (value: string) => {
    setSearchText(value);
    if (value.trim() === "") {
      setTransactionsData(originalConnectionData);
    } else {
      const filtered = originalConnectionData.filter(
        (item: any) =>
          item?.bu_name?.toLowerCase().includes(value?.toLowerCase()) ||
          item?.business_name?.toLowerCase().includes(value?.toLowerCase())
      );
      setTransactionsData(filtered);
    }
  };

  const handleConnectionTypeChange = (
    event: React.ChangeEvent<HTMLSelectElement>
  ) => {
    setConnectionType(event.target.value);
  };

  const getConnectionData = (data: any) => {
    console.log(data);
    if (connectionType === "External") {
      navigate(
        `/dashboard/organizations/org-details/${params.org}/online-connections/${data?.bu_id}/${data?.bu_name}`
      );
    } else {
      navigate(
        `/dashboard/organizations/org-details/${params.org}/online-connections/${data?.id}/${data?.business_name}`
      );
    }
  };
  return (
    <div className="">
      <div className="row">
        <div className="col-md-4 my-3">
          <input
            type="search"
            className="form-control p-2"
            placeholder="Search"
            value={searchText}
            onChange={(e) => handleInput(e.target.value)}
          />
        </div>
        <div className="col-md-4"></div>
        <div className="col-md-4 my-3">
          <Form.Select
            aria-label="Default select example"
            value={connectionType}
            onChange={handleConnectionTypeChange}
          >
            <option value="External">External</option>
            <option value="Internal">Internal</option>
          </Form.Select>
        </div>
      </div>
      <div className="col-12 px-0 my-2">
        {loading && (
          <div className="text-center p-5">
            <div className="spinner-border" role="status">
              <span className="sr-only"></span>
            </div>
          </div>
        )}
        {!loading &&
          transactionsData?.length > 0 &&
          transactionsData.map((value: any, index: any) => (
            <div
              className="card mb-2 cursor-pointer"
              key={index}
              onClick={() => getConnectionData(value)}
            >
              <div className="card-body  border-0 box-shadow py-2">
                <div className="d-flex align-items-center ">
                  <TiHomeOutline size={20} />
                  <span className="ms-2 fs-14 fw-600 mt-1">
                    {value?.bu_name} {value?.business_name}
                  </span>
                </div>
                <div className="d-flex align-items-center py-1">
                  <CiLocationOn size={20} />
                  <span className="ms-2 fs-14 fw-500 mt-1">{value?.city}</span>
                </div>
                <div className="d-flex align-items-center">
                  <PiPhoneCallLight size={20} />
                  <span className="ms-2 fs-14 fw-500 mt-1">
                    {value?.contact_number}
                  </span>
                </div>
              </div>
            </div>
          ))}
      </div>
    </div>
  );
};
