import { API } from "./axios.service";
export class OndcService {
  static async getOndcIgmData(): Promise<any> {
    return API.get(`/ondc/issue`);
  }

  static async updateOndcIgmData(data:any): Promise<any> {
    return API.post(`/ondc/respond_issue`,data);
  }
}
