import React, { useEffect, useState } from "react";
import { ApisService } from "../../../../../app/core/apis.service";
import {
  dataDecrypt,
  encrypt,
} from "../../../../../app/utility/encryption-helper";
import { DataTable } from "../../../../../components/data-table";
import { TransactionsDataGridCols } from "./data-grid-cols";
import { DateRangeSelector } from "../../../../../components/date-filter/daterangeselector";
import moment from "moment";
import { useParams } from "react-router-dom";
import { DateFormate } from "../../../../../app/utility/dateformate";

export const InvoicesList = () => {
  const params = useParams();
  const initialPayload = {
    buId: params.org,
    filters: {
      DateFrom: null,
      DateTo: null,
      status: null,
      name: null,
      transaction_type: null,
      offline: null,
    },
  };
  const [loading, setLoading] = useState<boolean>(false);
  const [pageCount, setPageCount] = useState<any>({
    limit: 10,
    page: 0,
  });
  const [search, setSearchText] = useState("");
  const [lastVisitFrom, setLastVisitFrom] = useState<any>();
  const [lastVisitTo, setLastVisitTo] = useState<any>();
  const [clearDates, setClearDates] = useState(false);
  const [selectDate, setSelectData] = useState<any>();
  const [transactionsData, setTransactionsData] = useState<any>([]);

  useEffect(() => {
    loadTransactions(initialPayload);
  }, []);
  async function loadTransactions(payload: any): Promise<void> {
    setLoading(true);
    try {
      let encryptedData = encrypt(JSON.stringify(payload));
      const res = await ApisService.transactionApi(
        "findByTransactionBuId",
        encryptedData
      );
      const transactionsData = JSON.parse(dataDecrypt(res.data.data));
      console.log(transactionsData, "transactionsData");
      const response = transactionsData?.results;
      console.log(response, "response");
      if (Array.isArray(response)) {
        response.forEach((element: any) => {
          element.invoice_date = element?.invoice_details
            ? moment(element?.invoice_details[0]?.invoice_date).format(
                "DD-MM-YYYY"
              )
            : "";
          element.buyer_app = element?.ondc?.buyer_app;
          element.sold_to = element?.sold_to;
          element.invoice = element?.invoice_details
            ? element?.invoice_details[0]?.bill_no
            : "";
          element.invoice_amount = element?.invoice_details
            ? element?.invoice_details[0]?.invoice_amount
            : "";
          element.packing_status =
            element?.invoice_details &&
            element?.invoice_details[0]?.package_details
              ? "completed"
              : "pending";
          element.delivery_status =
            element?.invoice_details &&
            element?.invoice_details[0]?.delivery_details
              ? "completed"
              : "pending";
        });
      } else {
        console.error("Error: 'response' is either undefined or not an array.");
      }

      const orderData = response.filter((order: any) => order?.type === "ONDC");
      console.log(orderData);
      setTransactionsData(orderData);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.error("Error in transaction:", error);
    }
  }


  useEffect(() => {
    if (selectDate) {
      setLastVisitFrom(DateFormate(selectDate[0], "YYYY-MM-DD"));
      setLastVisitTo(DateFormate(selectDate[1], "YYYY-MM-DD"));
    }
  }, [selectDate]);

  const onPageChange = (data: any) => {
    setPageCount((prev: any) => ({ ...prev, limit: data }));
  };

  const onSelectPage = (data: any) => {
    setPageCount((prev: any) => ({ ...prev, page: data }));
  };

  const getDateRange = (data: any) => {
    setSelectData(data);
  };

  const handleInput = (e: any) => {
    setSearchText(e.target.value);
  };

  const onSearchData = () => {
    const filters: any = {
      search,
      lastVisitFrom,
      lastVisitTo,
    };
    console.log("Searching with filters:", filters);

    if (filters.search || filters.lastVisitFrom || filters.lastVisitTo) {
      let payload: any = {
        buId: params.org,
        filters: {
          DateFrom: filters.lastVisitFrom ? filters.lastVisitFrom : null,
          DateTo: filters.lastVisitTo ? filters.lastVisitTo : null,
          status: null,
          name: filters.search ? filters.search : null,
          transaction_type: null,
          offline: null,
        },
      };
      console.log(payload)
      loadTransactions(payload);
    } else {
      console.log("Please fill at least one search field.");
    }
  };

  const onClear = () => {
    setSearchText("");
    loadTransactions(initialPayload);
    // setPageCount(initialPageCount);
    // getPatientsList(initialPageCount);
    setClearDates(true);
  };

  return (
    <div>
      <div className="row mt-1">
        <div className="col-md-4 text-start my-2">
          <div className="text-dark fs-6 fst-normal">Search</div>
          <input
            name="search"
            className="form-control py-2"
            placeholder="Search . . ."
            value={search}
            onChange={(e) => handleInput(e)}
          />
        </div>
        <div className="col-md-4 my-2">
          <div className="text-dark fs-6 fst-normal">Date</div>
          <DateRangeSelector
            handleDataRange={getDateRange}
            datefilterApply={false}
            clearDates={clearDates}
          />
        </div>
        <div className="col-md-4 my-2 text-end mt-4">
          <button
            className="btn btn-primary primary-bg px-4 py-2 mt-3"
            onClick={() => onSearchData()}
          >
            <span>Search</span>
          </button>
          <button
            className="btn btn-secondary px-4 py-2 mt-3 mx-2"
            onClick={() => onClear()}
          >
            <span>Clear</span>
          </button>
        </div>
      </div>
      <div className="col-12 px-0 my-2">
        {loading ? (
          <div className="text-center p-5">
            <div className="spinner-border" role="status">
              <span className="sr-only"></span>
            </div>
          </div>
        ) : (
          <DataTable
            tableData={transactionsData}
            TableCols={TransactionsDataGridCols}
            onActions={(e: any) => {}}
            onPageChange={(e: any) => onPageChange(e)}
            onSelectPageChange={(e: any) => onSelectPage(e)}
            pageCount={pageCount}
          />
        )}
      </div>
    </div>
  );
};
