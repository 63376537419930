import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { dataDecrypt, encrypt } from "../../../app/utility/encryption-helper";
import { ApisService } from "../../../app/core/apis.service";
import { IMAGE_PATH } from "../../../config";
import { Breadcrumb } from "../../breadcum";

export const ProductInfo = () => {
  const params = useParams();
  const [loading, setLoading] = useState<boolean>(false);
  const [productsData, setProductsData] = useState<any>([]);
  const [pageCount, setPageCount] = useState<any>({
    id: params.productId,
    buId: params.org,
  });

  useEffect(() => {
    loadProductInfo();
  }, []);

  async function loadProductInfo(): Promise<void> {
    setLoading(true);
    try {
      let encryptedData = encrypt(JSON.stringify(pageCount));
      const res = await ApisService.productApi("getById", encryptedData);
      const productsData = JSON.parse(dataDecrypt(res.data.data));
      setProductsData(productsData[0]);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.error("Error in transaction:", error);
    }
  }

  const [breadcrumbInfo, setBreadcrumbInfo] = useState<any>([
    {
      label: "Products",
      value: "Product",
      routerLink: `/dashboard/organizations/org-details/${params.org}/products`,
      isActive: false,
    },
    {
      label: `Info`,
      value: `Info`,
      routerLink: `/dashboard/organizations/org-details/${params.org}/products/${params.productId}`,
      isActive: true,
    },
  ]);

  return (
    <div className="container-fluid content-bg-color">
      <Breadcrumb breadCrumbList={breadcrumbInfo}></Breadcrumb>
      <div className="card border-0 shadow-sm">
        <div className="card-body">
          <div className="text-center">
            <img
              src={`${IMAGE_PATH}/${productsData?.images || "default.png"}`}
              alt="product image"
              className="rounded-top object-fit-cover"
              style={{ height: "200px" }}
            />
          </div>
        </div>
      </div>
      <div className="row">
        <h6 className="mt-3 fw-bold">
          <ins>PRODUCT DETAILS</ins> :
        </h6>
        <div className="col-6">Product Name</div>
        <div className="col-6 d-flex lh-sm">
          <span className="me-1">:</span>{" "}
          <span className="fs-14">{productsData?.name}</span>
        </div>
        <div className="col-6 mt-1">Description</div>
        <div className="col-6 d-flex lh-sm mt-1">
          <span className="me-1">:</span>{" "}
          <span className="fs-14">{productsData?.description}</span>
        </div>
        <div className="col-6 mt-1">Product Category</div>
        <div className="col-6 d-flex lh-sm mt-1">
          <span className="me-1">:</span>{" "}
          <span className="fs-14">{productsData?.product_category}</span>
        </div>
        <div className="col-6 mt-1">Product Sub_Category</div>
        <div className="col-6 d-flex lh-sm mt-1">
          <span className="me-1">:</span>{" "}
          <span className="fs-14">{productsData?.product_sub_category}</span>
        </div>

        <h6 className="mt-3 fw-bold">
          <ins>QUANTITY & PRICE DETAILS</ins> :
        </h6>
        <div className="col-6">Batch Number </div>
        <div className="col-6 d-flex lh-sm">
          <span className="me-1">:</span>
          <span className="fs-14">
            {productsData?.batch_pricing?.[0]?.batch_number || "N/A"}
          </span>
        </div>

        <div className="col-6 mt-1">Price</div>
        <div className="col-6 d-flex lh-sm mt-1">
          <span className="me-1">:</span>
          <span className="fs-14">
            {productsData?.batch_pricing?.[0]?.price || "N/A"}
          </span>
        </div>

        <div className="col-6 mt-1">Quantity</div>
        <div className="col-6 d-flex lh-sm mt-1">
          <span className="me-1">:</span>
          <span className="fs-14">
            {productsData?.batch_pricing?.[0]?.quantity || "N/A"}{" "}
            {productsData?.batch_pricing?.[0]?.quantity_in || ""}
          </span>
        </div>

        <div className="col-6 mt-1">Net Rate</div>
        <div className="col-6 d-flex lh-sm mt-1">
          <span className="me-1">:</span>
          <span className="fs-14">
            {productsData?.batch_pricing?.[0]?.net_rate || "N/A"}{" "}
          </span>
        </div>

        <div className="col-6 mt-1">MRP</div>
        <div className="col-6 d-flex lh-sm mt-1">
          <span className="me-1">:</span>
          <span className="fs-14">
            {productsData?.batch_pricing?.[0]?.mrp || "N/A"}{" "}
          </span>
        </div>

        <div className="col-6 mt-1">Manufacture Date</div>
        <div className="col-6 d-flex lh-sm mt-1">
          <span className="me-1">:</span>
          <span className="fs-14">
            {productsData?.batch_pricing?.[0]?.manufacture_date_time || "N/A"}{" "}
          </span>
        </div>

        <div className="col-6 mt-1">Expiration Date</div>
        <div className="col-6 d-flex lh-sm mt-1">
          <span className="me-1">:</span>
          <span className="fs-14">
            {productsData?.batch_pricing?.[0]?.expiry_date || "N/A"}{" "}
          </span>
        </div>

        <h6 className="mt-3 fw-bold">
          <ins>TAX DETAILS</ins> :
        </h6>
        <div className="col-6 mt-1">Tax Type</div>
        <div className="col-6 d-flex lh-sm mt-1">
          <span className="me-1">:</span>
          <span className="fs-14">{productsData?.tax?.type || "N/A"} </span>
        </div>

        <div className="col-6 mt-1">HSN Code</div>
        <div className="col-6 d-flex lh-sm mt-1">
          <span className="me-1">:</span>
          <span className="fs-14">{productsData?.tax?.hsn_code || "N/A"} </span>
        </div>

        <div className="col-6 mt-1">Value</div>
        <div className="col-6 d-flex lh-sm mt-1">
          <span className="me-1">:</span>
          <span className="fs-14">{productsData?.tax?.value || "N/A"} </span>
        </div>

        <h6 className="mt-3 fw-bold">
          <ins>MANUFACTURER</ins> :
        </h6>
        <div className="col-6 mt-1">Height</div>
        <div className="col-6 d-flex lh-sm mt-1">
          <span className="me-1">:</span>
          <span className="fs-14">
            {productsData?.shipping_details?.height}{" "}
          </span>
        </div>

        <div className="col-6 mt-1">Width</div>
        <div className="col-6 d-flex lh-sm mt-1">
          <span className="me-1">:</span>
          <span className="fs-14">
            {productsData?.shipping_details?.weight}{" "}
          </span>
        </div>

        <div className="col-6 mt-1">Depth</div>
        <div className="col-6 d-flex lh-sm mt-1">
          <span className="me-1">:</span>
          <span className="fs-14">
            {productsData?.shipping_details?.depth}{" "}
          </span>
        </div>

        <div className="col-6 mt-1">Volume</div>
        <div className="col-6 d-flex lh-sm mt-1">
          <span className="me-1">:</span>
          <span className="fs-14">
            {productsData?.shipping_details?.volume}{" "}
          </span>
        </div>

        <div className="col-6 mt-1">Weight</div>
        <div className="col-6 d-flex lh-sm mt-1">
          <span className="me-1">:</span>
          <span className="fs-14">
            {productsData?.shipping_details?.weight}{" "}
          </span>
        </div>

        <h6 className="mt-3 fw-bold">
          <ins>MANUFACTURER DETAILS</ins> :
        </h6>
        <div className="col-6 mt-1">Mobile Number</div>
        <div className="col-6 d-flex lh-sm mt-1">
          <span className="me-1">:</span>
          <span className="fs-14">
            {productsData?.manufacture_details?.model_number}{" "}
          </span>
        </div>

        <div className="col-6 mt-1">Release Date</div>
        <div className="col-6 d-flex lh-sm mt-1">
          <span className="me-1">:</span>
          <span className="fs-14">
            {productsData?.manufacture_details?.release_date}{" "}
          </span>
        </div>

        <div className="col-6 mt-1">Name</div>
        <div className="col-6 d-flex lh-sm mt-1">
          <span className="me-1">:</span>
          <span className="fs-14">
            {productsData?.manufacture_details?.name}{" "}
          </span>
        </div>

        <div className="col-6 mt-1">Plot No</div>
        <div className="col-6 d-flex lh-sm mt-1">
          <span className="me-1">:</span>
          <span className="fs-14">
            {productsData?.manufacture_details?.manufacturer_Address}{" "}
          </span>
        </div>

        <div className="col-6 mt-1">Building</div>
        <div className="col-6 d-flex lh-sm mt-1">
          <span className="me-1">:</span>
          <span className="fs-14">
            {productsData?.manufacture_details?.building_name}{" "}
          </span>
        </div>

        <div className="col-6 mt-1">Country</div>
        <div className="col-6 d-flex lh-sm mt-1">
          <span className="me-1">:</span>
          <span className="fs-14">
            {productsData?.manufacture_details?.country}{" "}
          </span>
        </div>

        <div className="col-6 mt-1">State</div>
        <div className="col-6 d-flex lh-sm mt-1">
          <span className="me-1">:</span>
          <span className="fs-14">
            {productsData?.manufacture_details?.state}{" "}
          </span>
        </div>

        <div className="col-6 mt-1">District</div>
        <div className="col-6 d-flex lh-sm mt-1">
          <span className="me-1">:</span>
          <span className="fs-14">
            {productsData?.manufacture_details?.district}{" "}
          </span>
        </div>

        <div className="col-6 mt-1">City</div>
        <div className="col-6 d-flex lh-sm mt-1">
          <span className="me-1">:</span>
          <span className="fs-14">
            {productsData?.manufacture_details?.city}{" "}
          </span>
        </div>

        <div className="col-6 mt-1">Pin Code</div>
        <div className="col-6 d-flex lh-sm mt-1">
          <span className="me-1">:</span>
          <span className="fs-14">
            {productsData?.manufacture_details?.pin}{" "}
          </span>
        </div>
      </div>
    </div>
  );
};
