import { DataTableCol } from "../../../components/data-table/types";

export const IgmDataGridCols: DataTableCol[] = [
    {
        title: 'BAP Id',
        control: 'bap_id',
        sortable: true,
    },
    {
        title: 'Category',
        control: 'category',
        sortable: true,
    },
    {
        title: 'Sub Category',
        control: 'sub_category',
        sortable: true,
    },
    {
        title: 'Issue Type',
        control: 'issue_type',
        sortable: true,
    },
    {
        title: 'Order Id',
        control: 'order_id',
        sortable: true,
    },
    {
        title: 'Status',
        control: 'status',
        sortable: true,
    },
    {
        title:'Messages',
        control:'view',
    }
];