import { useEffect, useState } from "react";
import DatePicker from "react-datepicker";

interface Props {
  handleDataRange?: (data: any) => void;
  datefilterApply?: boolean;
  clearDates?:boolean;
}

export const DateRangeSelector: React.FC<Props> = (props: Props) => {
  const [startDate, setStartDate] = useState<any>();
  const [endDate, setEndDate] = useState<any>();
  

  useEffect(() => {
    console.log(props.datefilterApply);
    if (!props.datefilterApply) {
      setStartDate("");
      setEndDate("");
    } else {
      setStartDate(new Date());
      setEndDate(new Date());
    }
  }, [props.datefilterApply]);

  useEffect(() => {
    console.log(props.clearDates);
    if (props.clearDates) {
      setStartDate("");
      setEndDate("");
    }
  }, [props.clearDates]);


  const handleDateChange = (dates: any) => {
    // const startDate = new Date(dates[0]);
    // const endDate = new Date(dates[1]);
    const [start, end] = dates;
    setStartDate(start);
    setEndDate(end);
    if (props.handleDataRange) {
      props.handleDataRange(dates);
    }
  };

  return (
    <div >
      <DatePicker
        selectsRange
        showIcon
        className="form-control py-2 w-100"
        placeholderText=" Select Date Range "
        startDate={startDate}
        endDate={endDate}
        monthsShown={2}
        onChange={handleDateChange}
      />
    </div>
  );
};
