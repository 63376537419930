import { DataTableCol } from "../../../components/data-table/types";


export const RegistrationDataGridCols: DataTableCol[] = [
    {
        title: 'Person Name',
        control: 'name',
        sortable: true,
        canShowColumn: true
    },
    {
        title: 'Mobile Number',
        control: 'contact',
        sortable: true,
        canShowColumn: true
    },
    {
        title: 'Registration Date',
        control: 'date',
        sortable: true,
        canShowColumn: true
    },
    {
        title: `No of Bu's`,
        control: `no_of_bu`,
        sortable: true,
        action: 'link',
        type: 'link',
        canShowColumn: true
    },
    {
        title: 'status',
        control: 'status',
        sortable: false,
        type:'toggle',
        canShowColumn: true
    },
   ];