import React, { useEffect, useState } from "react";
import { RegistrationDataGridCols } from "./data-grid-cols";
import { ApisService } from "../../../app/core/apis.service";
import { dataDecrypt, encrypt } from "../../../app/utility/encryption-helper";
import { DataTable } from "../../../components/data-table";
import { Search } from "../../../components/search/search";
import { DateRangeSelector } from "../../../components/date-filter/daterangeselector";
import moment from "moment";
import { Modal } from "react-bootstrap";

export const RegistrationDetails = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const [selectDate, setSelectData] = useState<any>();
  const [clearDates, setClearDates] = useState(false);
  const [toggleData, setToggleData] = useState<any>();
  const [originalRegistrationData, setOriginalRegistrationData] = useState<any>(
    []
  );
  const [show, setShow] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [pageCount, setPageCount] = useState<any>({
    limit: 10,
    page: 0,
  });
  const [registrationData, setRegistrationData] = useState<any>([]);

  useEffect(() => {
    loadUsers();
  }, []);

  async function loadUsers(): Promise<void> {
    setLoading(true);
    try {
      let encrytedData = "";
      const res = await ApisService.usersApi("getAll", "");
      const registrationData = JSON.parse(dataDecrypt(res.data.data));
      console.log(registrationData, "users adaa");
      const response = registrationData;
      console.log(response, "response");
      response.forEach((el: any) => {
        el.date = moment(el.created_date).format("DD-MM-YYYY");
        el.no_of_bu =
          el?.business_units?.length > 0 ? el.business_units.length : 0;
      });
      setRegistrationData(registrationData);
      setOriginalRegistrationData(registrationData);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.error("Error in transaction:", error);
    }
  }

  const onActions = (data: any) => {};

  const onPageChange = (data: any) => {
    const pageData = pageCount;
    pageData.limit = data;
    setPageCount(pageData);
  };

  const onSelectPage = (data: any) => {
    const pageData = pageCount;
    pageData.page = data;
    setPageCount(pageData);
  };
  const getDateRange = (data: any) => {
    setSelectData(data);
  };

  const closePopup = () => {
    setShow(false);
    loadUsers();
    setToggleData("");
  };

  const handleToggleChange = (updatedRow: any, index: number) => {
    setShow(true);
    setToggleData(updatedRow);
    const updatedData = [...registrationData];
    updatedData[index] = { ...updatedData[index], status: updatedRow.status };
    setRegistrationData(updatedData);
    setOriginalRegistrationData(updatedData);
  };

  const updateStatus = async (confirmation: string) => {
    if (confirmation === "yes") {
      let payload = {
        id: toggleData?._id,
        status: toggleData?.status ? 1 : 0,
      };
      let encrytedData = encrypt(JSON.stringify(payload));
      try {
        const res = await ApisService.usersApi("updateStatus", encrytedData);
        console.log(res);
      } catch (error) {
        console.log(error);
      }
    }
    setToggleData("");
    loadUsers();
    setShow(false);
  };
  const handleInput = (value: string) => {
    setSearchText(value);
    if (value.trim() === "") {
      setRegistrationData(originalRegistrationData);
    } else {
      const filtered = originalRegistrationData.filter(
        (item: any) =>
          item?.name?.toLowerCase().includes(value?.toLowerCase()) ||
          item?.contact?.includes(value?.toLowerCase())
      );
      setRegistrationData(filtered);
    }
  };

  return (
    <div
      className={`container-fluid content-bg-color ${
        show ? "blur-background" : ""
      }`}
    >
      <h4 className="primary-color fw-bold mt-3">Registrations</h4>
      <div className="row">
        <div className="col-md-4 my-3">
          <input
            type="search"
            className="form-control p-2"
            placeholder="Search"
            value={searchText}
            onChange={(e) => handleInput(e.target.value)}
          />
        </div>
      </div>
      <div className="px-0 my-2">
        {loading && (
          <div className="text-center p-5">
            <div className="spinner-border" role="status">
              <span className="sr-only"></span>
            </div>
          </div>
        )}
        {!loading && (
          <DataTable
            tableData={registrationData}
            TableCols={RegistrationDataGridCols}
            onActions={(e: any) => onActions(e)}
            onPageChange={(e: any) => onPageChange(e)}
            onSelectPageChange={(e) => onSelectPage(e)}
            pageCount={pageCount}
            onToggleChange={handleToggleChange}
          />
        )}
      </div>
      <Modal show={show} centered onHide={closePopup}>
        <Modal.Body className="text-center">
          <div className="fs-5 mb-2">
            Are you sure want to{" "}
            {toggleData?.status ? (
              <span className="fw-bold">Activate</span>
            ) : (
              <span className="fw-bold">De-Activate</span>
            )}{" "}
            user
          </div>
          <button
            className="w-25 rounded bg-white primary-border primary-color"
            onClick={() => updateStatus("no")}
          >
            NO
          </button>
          <button
            className="w-25 ms-2 rounded primary-bg border text-white"
            onClick={() => updateStatus("yes")}
          >
            YES
          </button>
        </Modal.Body>
      </Modal>
    </div>
  );
};
