import React from 'react';
import './assets/css/custom-css.css'
// import './assets/css/custom.css'
import "../node_modules/bootstrap/dist/css/bootstrap.css";
import { RoutesPage } from './Routes';
import { ToastContainer, toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import { Outlet } from 'react-router-dom';
import { Toaster } from 'react-hot-toast';

function App() {
  return (
    <div className='App'>
      <Toaster  position="top-right"/>
      <RoutesPage/>
      <Outlet/>
    </div>
  );
}

export default App;
