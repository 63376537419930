import React, { useEffect, useState } from "react";
import { Search } from "../../search/search";
import { DataTable } from "../../../components/data-table";
import { dataDecrypt, encrypt } from "../../../app/utility/encryption-helper";
import { ApisService } from "../../../app/core/apis.service";
import { useParams } from "react-router-dom";
import { UsersDataGridCols } from "./data-grid-cols";

export const OrgUsers = () => {
  const params = useParams();
  const [loading, setLoading] = useState<boolean>(false);
  const [organizationUsersData, setOrganizationUsersData] = useState<any>([]);
  const [originalUserData, setOriginalUserData] = useState<any>([]);
  const [searchText, setSearchText] = useState("");
  const [pageCount, setPageCount] = useState<any>({
    id: params.org,
    filters: { name: "" },
  });

  useEffect(() => {
    loadUsers();
  }, []);

  async function loadUsers(): Promise<void> {
    setLoading(true);
    try {
      let encryptedData = encrypt(JSON.stringify(pageCount));
      const res = await ApisService.usersApi("findByBuId", encryptedData);
      const userData = JSON.parse(dataDecrypt(res.data.data));
      const response = userData.results;
      response.forEach((el: any) => {
        el.status =
          el.status === 1 ? (
            <span className="text-success fw-bold">ACTIVE</span>
          ) : (
            <span className="text-danger fw-bold">In-Active</span>
          );
      });
      setOriginalUserData(userData?.results);
      setOrganizationUsersData(userData?.results);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.error("Error in transaction:", error);
    }
  }

  const onPageChange = (data: any) => {
    const pageData = pageCount;
    pageData.limit = data;
    setPageCount(pageData);
  };

  const onSelectPage = (data: any) => {
    const pageData = pageCount;
    pageData.page = data;
    setPageCount(pageData);
  };

  const handleInput = (value: string) => {
    setSearchText(value);
    if (value.trim() === "") {
      setOrganizationUsersData(originalUserData);
    } else {
      const filtered = originalUserData.filter(
        (item: any) =>
          item?.name?.toLowerCase().includes(value?.toLowerCase()) ||
          item?.contact.includes(value?.toLowerCase())
      );
      setOrganizationUsersData(filtered);
    }
  };

  return (
    <div className="px-3">
      <div className="row">
        <div className="col-md-4 my-3">
          <input
            type="search"
            className="form-control p-2"
            placeholder="Search"
            value={searchText}
            onChange={(e) => handleInput(e.target.value)}
          />
        </div>
      </div>

      {loading && (
        <div className="text-center p-5">
          <div className="spinner-border" role="status">
            <span className="sr-only"></span>
          </div>
        </div>
      )}
      {!loading && (
        <>
          <DataTable
            tableData={organizationUsersData}
            TableCols={UsersDataGridCols}
          />
        </>
      )}
    </div>
  );
};
