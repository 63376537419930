import React, { SyntheticEvent, useEffect, useState } from "react";
import { MenuSystemItem } from "../../../app/model/menu-system-item";
import { ApisService } from "../../../app/core/apis.service";
import { dataDecrypt, encrypt } from "../../../app/utility/encryption-helper";
import { MenuSystemUpdates } from "../../../app/core/menu-system-updates";
import { MenuSystemUpdateItem } from "../../../app/model/menu-system-update-item";
import { MenuSystemItemView } from "./menu-system-item-view";
import toast from "react-hot-toast";

interface Props {
  type: "system" | "bu";
  bu?: string;
}
export const MenuSystem = (props: Props) => {
  const [menuSystemData, setMenuSystemData] = useState<MenuSystemItem[]>([]);
  const [buFeatures, setBuFeatures] = useState<string[]>([]);
  const [canShowScuccess, setCanShowScuccess] = useState(false);
  const [totalKeys, setTotalKeys] = useState(0);
  const [searchText, setSearchText] = useState("");
  const [isLoaded, setIsLoaded] = useState(false);
  const [originalPreviligesData, setOriginalPreviligesData] = useState<any>([]);
  const [isReady, setIsReady] = useState(false);

  useEffect(() => {
    loadMenuSystem();
    return () => {
      setMenuSystemData([]);
    };
  }, []);

  useEffect(() => {
    if (menuSystemData && menuSystemData.length) {
      if (props.type === "system") {
        prepareMenuSystemUpdate();
        // setIsLoaded(true);
      }
    }
  }, [menuSystemData]);

  useEffect(() => {
    console.log('setting bu features ', menuSystemData);

    if (menuSystemData && menuSystemData.length) {
      prepareMenuSystemUpdate();
      // setIsLoaded(true);
    }
  }, [buFeatures]);

  const addParentLabel = (menu: any, parentLabel = null) => {
    return menu.map((item: any) => {
      const newItem = { ...item };
      if (parentLabel) {
        newItem.parentLabel = parentLabel;
      }
      if (newItem.children && newItem.children.length > 0) {
        newItem.children = addParentLabel(newItem.children, newItem.label);
      }
      return newItem;
    });
  };

  const loadMenuSystem = async () => {
    setIsLoaded(true);
    setIsReady(false);
    try {
      const res = await ApisService.getOrganizationData("getOrgMenuSystem", "");
      setIsLoaded(false);
      setIsReady(true);
      if (res.data && res.data.data) {
        const menuData = JSON.parse(dataDecrypt(res.data.data));
        console.log(menuData, "menuData");
        const updatedMenuSystemData = addParentLabel(menuData.menus);
        setMenuSystemData(updatedMenuSystemData);
        setOriginalPreviligesData(updatedMenuSystemData);
        if (props.bu) {
          try {
            const buRes = await ApisService.getOrganizationData(
              "findById",
              encrypt(JSON.stringify({ id: props.bu }))
            );
            const buResData = JSON.parse(dataDecrypt(buRes.data.data));
            setBuFeatures(buResData[0].features);
          } catch (error) {
            console.error("Error while loading BU Features", error);
          }
        }
      }
    } catch (error) {
      setIsLoaded(false);
      console.log(error);
    }
  };

  const prepareMenuSystemUpdate = () => {
    setTotalKeys(0);
    const preparedMenuSystem: MenuSystemUpdateItem[] = [];
    for (let index = 0; index < menuSystemData.length; index++) {
      const element = menuSystemData[index];
      if (props.type === "system") {
        preparedMenuSystem.push({
          code: element.code,
          enabled: element.enabled,
        });
      } else if (props.type === "bu") {
        let isEnabled = buFeatures?.find((el) => el === element.code)
          ? true
          : false;

        console.log('isEnabled ', isEnabled, element.code);

        if (!buFeatures) {
          isEnabled = element.enabled;
        }
        preparedMenuSystem.push({
          code: element.code,
          enabled: isEnabled ? true : false,
        });
      }
      if (element.children) {
        prepareInnerMenuItem(element.children, preparedMenuSystem);
      }
      setTotalKeys((prev) => prev + 1);
    }
    MenuSystemUpdates.menuSystemItems = preparedMenuSystem;
    console.log("prepared ", MenuSystemUpdates.menuSystemItems);
  };

  const prepareInnerMenuItem = (
    menuItems: MenuSystemItem[],
    preparedMenuSystem: MenuSystemUpdateItem[]
  ) => {
    for (let index = 0; index < menuItems.length; index++) {
      const element = menuItems[index];
      if (props.type === "system") {
        preparedMenuSystem.push({
          code: element.code,
          enabled: element.enabled,
        });
      } else if (props.type === "bu") {
        let isEnabled = buFeatures?.find((el) => el === element.code)
          ? true
          : false;
        if (!buFeatures) {
          isEnabled = element.enabled;
        }

        preparedMenuSystem.push({
          code: element.code,
          enabled: isEnabled ? true : false,
        });
      }
      if (element.children) {
        prepareInnerMenuItem(element.children, preparedMenuSystem);
      }
      setTotalKeys((prev) => prev + 1);
    }
  };

  const onSaveSystem = async () => {
    setIsLoaded(true);
    if (props.type === "system") {
      const menuData = encrypt(
        JSON.stringify(MenuSystemUpdates.menuSystemItems)
      );
      try {
        await ApisService.getOrganizationData("updateOrgMenuSystem", menuData);
        setIsLoaded(false);
        toast.success("Saved successfully");
      } catch (error) {
        console.log(error);
        setIsLoaded(false);
      }
    } else if (props.type === "bu") {
      const orgFeatureReq = {
        bu: props.bu,
        features: MenuSystemUpdates.menuSystemItems
          .filter((el) => el.enabled)
          .map((el) => el.code),
      };
      const menuData = encrypt(JSON.stringify(orgFeatureReq));
      console.log("org feature data ", orgFeatureReq, props);
      try {
        await ApisService.getOrganizationData("updateOrgFeatures", menuData);
        setIsLoaded(false);
        toast.success("Saved successfully");
      } catch (error) {
        console.log(error);
        setIsLoaded(false);
      }
    }
    setCanShowScuccess(true);
  };

  const mergeFeatures = () => {
    console.log("mergeFeatures");
  };

  const handleInput = (value: string) => {
    setSearchText(value);
    const filterRecursive = (data: any[], searchTerm: string): any[] => {
      return data
        .map((item) => {
          const matches =
            item?.label?.toLowerCase().includes(searchTerm.toLowerCase()) ||
            item?.code?.toLowerCase().includes(searchTerm.toLowerCase()) ||
            item?.type?.toLowerCase().includes(searchTerm.toLowerCase());
          const filteredChildren = item.children
            ? filterRecursive(item.children, searchTerm)
            : [];
          if (matches || filteredChildren.length > 0) {
            return { ...item, children: filteredChildren };
          }
          return null;
        })
        .filter(Boolean);
    };

    if (value.trim() === "") {
      setMenuSystemData(originalPreviligesData);
    } else {
      const filtered = filterRecursive(originalPreviligesData, value);
      setMenuSystemData(filtered);
    }
  };

  const updateChildren = (children: any[], isChecked: boolean): any[] => {
    return children.map((child) => {
      const updatedChild = {
        ...child,
        enabled: isChecked,
      };

      const detectedItem = MenuSystemUpdates.menuSystemItems?.find(
        (el) => el.code === child.code
      );
      if (detectedItem) {
        detectedItem.enabled = isChecked;
      }

      if (child.children && child.children.length > 0) {
        updatedChild.children = updateChildren(child.children, isChecked);
      }

      return updatedChild;
    });
  };

  const updateMenuSystem = (menuItems: any[], targetCode: string, isChecked: boolean): any[] => {
    return menuItems.map((item) => {
      if (item.code === targetCode) {
        const updatedItem = {
          ...item,
          enabled: isChecked,
        };

        const detectedItem = MenuSystemUpdates.menuSystemItems?.find(
          (el) => el.code === item.code
        );
        if (detectedItem) {
          detectedItem.enabled = isChecked;
        }
        if (item.children && item.children.length > 0) {
          updatedItem.children = updateChildren(item.children, isChecked);
        }
        return updatedItem;
      } else if (item.children && item.children.length > 0) {
        return {
          ...item,
          children: updateMenuSystem(item.children, targetCode, isChecked),
        };
      }
      return { ...item };
    });
  };

  const onChangeVisibility = (event: SyntheticEvent, item: any) => {
    const inputEl = event.target as HTMLInputElement;
    const isChecked = inputEl.checked;

    const updatedData = updateMenuSystem(menuSystemData, item.code, isChecked);

    console.log(updatedData);
    setMenuSystemData([...updatedData]);
    console.log("MenuSystemUpdates.menuSystemItems", MenuSystemUpdates.menuSystemItems);
  };

  const getIsItemEnabled = (element: MenuSystemItem) => {
    if(props.type === 'bu') {
      const menuSystemElement = MenuSystemUpdates.menuSystemItems?.find(el=>el.code === element.code);
      if(menuSystemElement) {
        return menuSystemElement.enabled;
      }
      return false;
    }
    return element.enabled;
  }

  const renderMenuItem = (el: MenuSystemItem) => (
    <div className="ms-3" key={`${el.code}_${props.type}`}>
      <input
        type="checkbox"
        className="me-3"
        onChange={(event) => onChangeVisibility(event, el)}
        // checked={el?.enabled || false}
        checked={getIsItemEnabled(el)}

      />
      <span>
        {el.label} <small className="text-primary">({el.code}) {el?.enabled ? 'true' : 'false'}</small>
      </span>
      {el.children && el.children.length > 0 && (
        <div className="ms-3">
          {el.children.map((child: MenuSystemItem) => renderMenuItem(child))}
        </div>
      )}
    </div>
  );

  return (
    <div className="p-5 content-bg-color">
      {isLoaded && (
        <div className="text-center p-5">
          <div className="spinner-border" role="status">
            <span className="sr-only"></span>
          </div>
        </div>
      )}
      {!isLoaded && isReady && (
        <div>
          <div className="row mb-2 align-items">
            <div className="col-md-4">
              <div>Search</div>
              <input
                type="search"
                className="form-control p-2"
                placeholder="Search"
                value={searchText}
                onChange={(e) => handleInput(e.target.value)}
              />
            </div>
          </div>
          <b>Total Keys:</b> {totalKeys}
          {menuSystemData?.map((el: any) => renderMenuItem(el))}

          {/* {menuSystemData?.map((el: any, dIndex: any) => (
            <>
              {el.enabled}
              {(el.enabled || props.type === "system") && (
                <div className="ms-3" key={`${el.code}_${props.type}`}>
                  <input
                    type="checkbox"
                    name=""
                    id=""
                    className="me-3"
                    onChange={(event) => onChangeVisibility(event, el)}
                    defaultChecked={el?.enabled}
                  />
                  <span>
                    {el.label}{" "}
                    <small className="text-primary">({el.code})</small>
                  </span>
                  {el.children &&
                    el.children.map((childEl: any) => (
                      <MenuSystemItemView
                        type={props.type}
                        key={`${childEl.code}_${props.type}`}
                        item={childEl}
                        menuSystemData={menuSystemData}
                      ></MenuSystemItemView>
                    ))}
                </div>
              )}
            </>
          ))} */}
          {/* {canShowScuccess && (
            <div className="text-success text-center">Saved successfully</div>
          )} */}
          <div className="text-end me-4">
            <button
              className="btn btn-primary position-fixed mb-5 bottom-0 right-0"
              onClick={onSaveSystem}
            >
              Save
            </button>
          </div>
        </div>
      )}
    </div>
  );
};
