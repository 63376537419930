import React, { useEffect, useState } from "react";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import { TicketDataGridCols } from "./data-grid-cols";
import { DataTable } from "../../../components/data-table";
import { LookupService } from "../../../app/core/lookup.service";

export const Tickets = () => {
  const navigate=useNavigate();
  const [loading, setLoading] = useState(false);
  const [ticketsData, setTicketsData] = useState<any>();
  const [originalTicketsData, setOriginalTicketsData] = useState<any>([]);
  const [searchText, setSearchText] = useState("");
  const [canShowForm, setCanShowForm] = useState(false);
  const [editData, setEditData] = useState(false);

  useEffect(() => {
    loadTickets();
  }, []);

  const loadTickets = async () => {
    setLoading(true);
    try {
      const featureGroups = await LookupService.getTicketsInfo();
      setLoading(false);
      featureGroups.forEach((el:any)=>{
        el.created_dt=moment(el?.created_dt).format("DD-MM-YYYY");
        el.view=<button className="px-5 py-1 rounded primary-bg text-white border-0" onClick={()=>getTicketData(el)}>View</button>
      })
      setTicketsData(featureGroups);
      setOriginalTicketsData(featureGroups);
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };

  const handleInput = (value: string) => {
    setSearchText(value);
    if (value.trim() === "") {
      setTicketsData(originalTicketsData);
    } else {
      const filtered = originalTicketsData.filter(
        (item: any) =>
          item.name.toLowerCase().includes(value?.toLowerCase())
      );
      setTicketsData(filtered);
    }
  };

  const getTicketData=(data:any)=>{
    console.log(data,"ticket data");
    navigate(`/dashboard/tickets/${data?._id}`)
  }

  return (
    <div className="container-fluid content-bg-color">
      {!(canShowForm || editData) && (
        <div className="row">
          <div className="col-12">
            <div className="row">
              <div className="col-12 text-end d-flex justify-content-between pl-8">
                <h4 className="primary-color fw-bold mt-3">Tickets</h4>
              </div>
            </div>
            <div className="row">
              <div className="col-md-4 my-3">
                <input
                  type="search"
                  className="form-control p-2"
                  placeholder="Search"
                  value={searchText}
                  onChange={(e) => handleInput(e.target.value)}
                />
              </div>
              <div className="col-md-3 my-3"></div>
            </div>
            <div className="row ">
              <div className="col-12 px-0">
                {loading && (
                  <div className="text-center p-5">
                    <div className="spinner-border" role="status">
                      <span className="sr-only"></span>
                    </div>
                  </div>
                )}
                {!loading &&
                  <>
                    <DataTable
                      tableData={ticketsData}
                      TableCols={TicketDataGridCols}
                    />
                  </>
                  }
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};
