import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import {
  dataDecrypt,
  encrypt,
} from "../../../../../app/utility/encryption-helper";
import { ApisService } from "../../../../../app/core/apis.service";
import Accordion from "react-bootstrap/Accordion";
import { Breadcrumb } from "../../../../../components/breadcum";

export const BusinessUnitDetails = () => {
  const params = useParams();
  const [buDetails, setBuDetails] = useState<any>();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    getExternalBuData();
  }, [params]);

  async function getExternalBuData(): Promise<void> {
    setLoading(true);
    try {
      let payload = {
        id: params.buId,
      };
      let encryptedData = encrypt(JSON.stringify(payload));
      const apiRes = await ApisService.getOrganizationData(
        "findById",
        encryptedData
      );
      const buData = JSON.parse(dataDecrypt(apiRes.data.data));
      setBuDetails(buData);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.error("Error in transaction:", error);
    }
  }

  const [breadcrumbInfo, setBreadcrumbInfo] = useState<any>([
    {
      label: `${params?.type}`,
      value: `${params?.type}`,
      routerLink: `/dashboard/organizations/org-details/${params.org}/online-connections`,
      isActive: false,
    },
    {
      label: `Info`,
      value: `Info`,
      routerLink: ``,
      isActive: true,
    },
  ]);

  return (
    <div className="container-fluid content-bg-color">
      <Breadcrumb breadCrumbList={breadcrumbInfo}></Breadcrumb>
      <h5 className="text-center mb-0 fw-600">Business Unit Details</h5>
      <div className="text-center">{params?.type}</div>
      {buDetails?.length > 0 ? (
        <div>
          <Accordion className="mt-5 mb-2">
            <Accordion.Item eventKey="0">
              <Accordion.Header><span className="fs-14">BASIC DETAILS</span></Accordion.Header>
              <Accordion.Body className="p-0">
                <div className="row g-0 fs-14">
                  <div className="col-6 py-2"><span className="ms-2">Business Name</span></div>
                  <div className="col-6 py-2">: <span className="ms-2">{buDetails[0].business_name}</span></div>
                  <hr className="my-0 col-12 text-dark" />
                  <div className="col-6 py-2"><span className="ms-2">Contact Number</span></div>
                  <div className="col-6 py-2">: <span className="ms-2">{buDetails[0].contact_number}</span></div>
                  <hr className="my-0 col-12" />
                  <div className="col-6 py-2"><span className="ms-2">Head Of Business</span></div>
                  <div className="col-6 py-2">: <span className="ms-2">{buDetails[0].head_of_business}</span></div>
                  <hr className="my-0 col-12" />
                  <div className="col-6 py-2"><span className="ms-2">Website</span></div>
                  <div className="col-6 py-2">: <span className="ms-2">{buDetails[0].website}</span></div>
                  <hr className="my-0 col-12" />
                  <div className="col-6 py-2"><span className="ms-2">Business Type</span></div>
                  <div className="col-6 py-2">: <span className="ms-2">{buDetails[0].type}</span></div>
                  <hr className="my-0 col-12" />
                  <div className="col-6 py-2"><span className="ms-2">Country Code</span></div>
                  <div className="col-6 py-2">: <span className="ms-2">{buDetails[0].country_code}</span></div>
                  <hr className="my-0 col-12" />
                  <div className="col-6 py-2"><span className="ms-2">Currency Code</span></div>
                  <div className="col-6 py-2">: <span className="ms-2">{buDetails[0].currency_code}</span></div>
                  <hr className="my-0 col-12" />
                  <div className="col-6 py-2"><span className="ms-2">GST No</span></div>
                  <div className="col-6 py-2">: <span className="ms-2">{buDetails[0].GST_No}</span></div>
                </div>
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>

          {buDetails[0]?.locations.length > 0 && <Accordion className="my-2">
            <Accordion.Item eventKey="0">
              <Accordion.Header><span className="fs-14">CONTACT DETAILS</span></Accordion.Header>
              <Accordion.Body className="p-0">
              <div className="row g-0 fs-14">
                  <div className="col-6 py-2"><span className="ms-2">Contact Person</span></div>
                  <div className="col-6 py-2">: <span className="ms-2">{buDetails[0]?.locations[0]?.address?.contact_person}</span></div>
                  <hr className="my-0 col-12 text-dark" />
                  <div className="col-6 py-2"><span className="ms-2">Contact Number</span></div>
                  <div className="col-6 py-2">: <span className="ms-2">{buDetails[0]?.locations[0]?.address?.contact_number}</span></div>
                  <hr className="my-0 col-12" />
                  <div className="col-6 py-2"><span className="ms-2">Plot No</span></div>
                  <div className="col-6 py-2">: <span className="ms-2">{buDetails[0]?.locations[0]?.address?.plot_no}</span></div>
                  <hr className="my-0 col-12" />
                  <div className="col-6 py-2"><span className="ms-2">House No</span></div>
                  <div className="col-6 py-2">: <span className="ms-2">{buDetails[0]?.locations[0]?.address?.buildinghousenumber}</span></div>
                  <hr className="my-0 col-12" />
                  <div className="col-6 py-2"><span className="ms-2">Building</span></div>
                  <div className="col-6 py-2">: <span className="ms-2">{buDetails[0]?.locations[0]?.address?.building_no}</span></div>
                  <hr className="my-0 col-12" />
                  <div className="col-6 py-2"><span className="ms-2">Street Name</span></div>
                  <div className="col-6 py-2">: <span className="ms-2">{buDetails[0]?.locations[0]?.address?.buildingstreetname}</span></div>
                  <hr className="my-0 col-12" />
                  <div className="col-6 py-2"><span className="ms-2">Locality</span></div>
                  <div className="col-6 py-2">: <span className="ms-2">{buDetails[0]?.locations[0]?.address?.colonysector_arealocality}</span></div>
                  <hr className="my-0 col-12" />
                  <div className="col-6 py-2"><span className="ms-2">Landmark</span></div>
                  <div className="col-6 py-2">: <span className="ms-2">{buDetails[0]?.locations[0]?.address?.landmark}</span></div>
                  <hr className="my-0 col-12" />
                  <div className="col-6 py-2"><span className="ms-2">Country</span></div>
                  <div className="col-6 py-2">: <span className="ms-2">{buDetails[0]?.locations[0]?.address?.country}</span></div>
                  <hr className="my-0 col-12" />
                  <div className="col-6 py-2"><span className="ms-2">State</span></div>
                  <div className="col-6 py-2">: <span className="ms-2">{buDetails[0]?.locations[0]?.address?.state}</span></div>
                  <hr className="my-0 col-12" />
                  <div className="col-6 py-2"><span className="ms-2">District</span></div>
                  <div className="col-6 py-2">: <span className="ms-2">{buDetails[0]?.locations[0]?.address?.district}</span></div>
                  <hr className="my-0 col-12" />
                  <div className="col-6 py-2"><span className="ms-2">City</span></div>
                  <div className="col-6 py-2">: <span className="ms-2">{buDetails[0]?.locations[0]?.address?.villagetowncity}</span></div>
                  <hr className="my-0 col-12" />
                  <div className="col-6 py-2"><span className="ms-2">Pin Code</span></div>
                  <div className="col-6 py-2">: <span className="ms-2">{buDetails[0]?.locations[0]?.address?.postcode}</span></div>
                </div>
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>}

          <Accordion className="my-2">
            <Accordion.Item eventKey="0">
              <Accordion.Header><span className="fs-14">PAYMENT TERMS</span></Accordion.Header>
              <Accordion.Body className="p-0">
              <div className="row g-0 fs-14">
                  <div className="col-6 py-2"><span className="ms-2">Invoice Currency Code</span></div>
                  <div className="col-6 py-2">: <span className="ms-2">{buDetails[0]?.payment_terms?.invoice_currency_code}</span></div>
                  <hr className="my-0 col-12 text-dark" />
                  <div className="col-6 py-2"><span className="ms-2">Invoice Payment Terms</span></div>
                  <div className="col-6 py-2">: <span className="ms-2">{buDetails[0]?.payment_terms?.invoice_payment_terms}</span></div>
                  <hr className="my-0 col-12" />
                  <div className="col-6 py-2"><span className="ms-2">Payment Terms</span></div>
                  <div className="col-6 py-2">: <span className="ms-2">{buDetails[0]?.payment_terms?.terms.length}</span></div>
                  <hr className="my-0 col-12" />
                  <div className="col-6 py-2"><span className="ms-2">Penalty Reason</span></div>
                  <div className="col-6 py-2">: <span className="ms-2">{buDetails[0]?.payment_terms?.penalty_amount?.value}</span></div>
                  <hr className="my-0 col-12" />
                  <div className="col-6 py-2"><span className="ms-2">Advance Payment</span></div>
                  <div className="col-6 py-2">: <span className="ms-2">{buDetails[0]?.payment_terms?.penalty_amount?.advance_payment?.value}</span></div>
                  <hr className="my-0 col-12" />
                  </div>
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
        </div>
      ) : (
        <div
          className="d-flex justify-content-center align-items-center"
          style={{ height: "80vh" }}
        >
          <div className="text-center">Data Not Found</div>
        </div>
      )}
    </div>
  );
};
