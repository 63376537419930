import CryptoJS from 'crypto-js';

import { ENCRYPTION_KEY, IV_KEY } from "../../config";

export const dataDecrypt = (data: string): string => {
    
    const key = CryptoJS.enc.Utf8.parse(ENCRYPTION_KEY);
    const iv = CryptoJS.enc.Utf8.parse(IV_KEY);
    const encryptedData = CryptoJS.enc.Hex.parse(data);
    const cipherParams: any = {
        ciphertext: encryptedData,
    };

    const decrypted = CryptoJS.AES.decrypt(cipherParams, key, {
        iv: iv,
        mode: CryptoJS.mode.CBC,
        padding: CryptoJS.pad.Pkcs7,
    });

    return decrypted.toString(CryptoJS.enc.Utf8);
};


// export const encrypt = (request: string): string => {

//     console.log(request, "Requested Data...")
//     let _key = CryptoJS.enc.Utf8.parse(ENCRYPTION_KEY);
//     let _iv = CryptoJS.enc.Utf8.parse(IV_KEY);
//     let encrypted = CryptoJS.AES.encrypt(
//         request, _key, {
//         keySize: 16,
//         iv: _iv,
//         mode: CryptoJS.mode.CBC,
//         padding: CryptoJS.pad.Pkcs7
//     });
//     let encryptedStr = encrypted.toString();

//     const keyHex = CryptoJS.enc.Utf8.parse(ENCRYPTION_KEY);
//     const text = CryptoJS.enc.Utf8.parse(request);

//     // Perform AES encryption
//     const encryptedUpdated = CryptoJS.AES.encrypt(text, keyHex, {
//         mode: CryptoJS.mode.ECB, // Electronic Codebook mode
//         padding: CryptoJS.pad.Pkcs7 // PKCS#7 padding
//     });

//     // Convert the ciphertext to a base64 string
//     // const ciphertext = encryptedUpdated.toString(CryptoJS.enc.Base64);
//     console.log('encryptedUpdated ', encryptedUpdated.toString());
    
//     return encryptedStr;
// }


export const encrypt = (data:any) => {
    const key = CryptoJS.enc.Utf8.parse(ENCRYPTION_KEY);
    const iv = CryptoJS.enc.Utf8.parse(IV_KEY);
  
    const encrypted = CryptoJS.AES.encrypt(data, key, {
      iv: iv,
      mode: CryptoJS.mode.CBC,
      padding: CryptoJS.pad.Pkcs7,
    });
  
    // The encrypted data is typically in base64 format, but you can convert it to a hexadecimal representation
    return encrypted.ciphertext.toString(CryptoJS.enc.Hex);
  };