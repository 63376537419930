import { API } from "./axios.service";

export class LookupService {
  static async getAllFeatureGrups(): Promise<any> {
    return API.get("api/v1/lookup/user-feature-group");
  }

  static async addNewPrevilige(data: any): Promise<any> {
    return API.post("api/v1/lookup/privileges", data);
  }

  static async getPrevilige(): Promise<any> {
    return API.get("api/v1/lookup/privileges");
  }

  static async updatePrevilige(data: any): Promise<any> {
    return API.put(`api/v1/lookup/privileges`, data);
  }

  static async getTicketsInfo(): Promise<any> {
    return API.get("api/v1/notification/ticket");
  }
  static async updateTicketsInfo(data: any): Promise<any> {
    return API.put(`api/v1/notification/ticket/update`, data);
  }
}
