import { useEffect, useState } from "react";
import { LookupService } from "../../../app/core/lookup.service";
import { useParams } from "react-router-dom";
import { Avatar } from "@mui/material";
import { FormatIndianDate } from "../../../app/utility/formatIndianDate";
import { Breadcrumb } from "../../../components/breadcum";

export const MessageView = () => {
  const params = useParams();
  const [loading, setLoading] = useState(false);
  const [ticketsData, setTicketsData] = useState<any>();
  const [replayData, setReplayData] = useState<any>();
  const [replayTicket, setReplayTicket] = useState<boolean>(false);

  useEffect(() => {
    setTicketsData("");
    loadTickets();
  }, []);
  const loadTickets = async () => {
    setLoading(true);
    try {
      const messageData = await LookupService.getTicketsInfo();
      setLoading(false);
      const response = messageData.filter((el: any) => el._id === params.id);
      setTicketsData(response[0]);
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };

  const writeReplay = () => {
    setReplayTicket(true);
  };

  const onSubmitData = async () => {
    let payload = {
      _id: params.id,
      email: "Admin",
      message: replayData,
      name: ticketsData?.name,
      subject: ticketsData?.subject,
    };
    try {
      const res = await LookupService.updateTicketsInfo(payload);
      if (res) {
        setReplayTicket(false);
        setReplayData("");
        loadTickets();
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleInput = (e: any) => {
    setReplayData(e.target.value);
  };

  const [breadcrumbInfo, setBreadcrumbInfo] = useState<any>([
    {
      label: "Tickets",
      value: "Tickets",
      routerLink: `/dashboard/tickets`,
      isActive: false,
    },
    {
      label: "Info",
      value: "Info",
      routerLink: ``,
      isActive: true,
    },
  ]);

  return (
    <div className="container-fluid content-bg-color">
      <Breadcrumb breadCrumbList={breadcrumbInfo} />
      {!loading ? (
        <div>
          <h5 className="fw-600">Ticket ID : {params.id}</h5>
          <div className="card mb-2">
            <div className="card-body">
              <h6 className="fw-bold fs-5 mb-2">Ticket details</h6>
              <h6 className="mb-0 fw-600">Subject</h6>
              <div className="fs-14">{ticketsData?.subject}</div>

              <h6 className="fw-bold mb-0 mt-2">Ticket ID</h6>
              <div className="fs-14">{ticketsData?._id}</div>

              <h6 className="fw-bold mb-0 mt-2">Created</h6>
              <div className="fs-14">
                {FormatIndianDate(ticketsData?.created_dt, "")}
              </div>

              <h6 className="fw-bold mb-0 mt-2">Business Units</h6>
              <div className="fs-14">{ticketsData?.name}</div>
            </div>
          </div>
          <div>
            {!replayTicket ? (
              <div className="text-end">
                <button
                  className="primary-bg rounded px-4 py-1 text-white border-0 my-3"
                  onClick={writeReplay}
                >
                  Reply
                </button>
              </div>
            ) : (
              <div className="my-3">
                <label className="fw-600">Write your reply here</label>
                <textarea
                  className="form-control"
                  id="exampleTextarea"
                  placeholder="Enter your message here"
                  onChange={(e) => handleInput(e)}
                ></textarea>
                <div className="text-end">
                  <button
                    className="primary-bg rounded px-4 py-1 text-white border-0 mb-3 mt-1"
                    onClick={onSubmitData}
                  >
                    Submit
                  </button>
                </div>
              </div>
            )}
          </div>
          {ticketsData?.messages?.map((value: any, index: any) => (
            <div className="mb-2" key={index}>
              <div className="card border-30 box-shadow rounded">
                <div className="card-body">
                  <h6 className="fw-600">
                    {value?.sent_by_email === "Admin"
                      ? `Correspondence`
                      : ""}
                  </h6>
                  <div className="row">
                    <div className="col-4 right-border">
                      <div>{value?.sent_by_email}</div>
                      <div className="fs-14">
                        {FormatIndianDate(value?.created_at, "datetime")}
                      </div>
                    </div>
                    <div className="col-8">{value?.message}</div>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      ) : (
        <div>
          <div className="text-center p-5">
            <div className="spinner-border" role="status">
              <span className="sr-only"></span>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};
