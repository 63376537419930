import { DataTableCol } from "../../../components/data-table/types";

export const TicketDataGridCols: DataTableCol[] = [
    {
        title: 'From',
        control: 'name',
        sortable: true,
        // type: 'link',
        // action: 'link'
    },
    {
        title: 'Subject',
        control: 'subject',
        sortable: true,
    },
    {
        title: 'Created Date',
        control: 'created_dt',
        sortable: true,
    },
    {
        title:'Messages',
        control:'view',
    }
];